.container {
  width: auto;
  margin: 0 auto;
}

.content {
  position: relative;
  margin: 50px 80px;
  height: 470px;
  padding: 50px;
  text-align: left;
  font-size: 1.125rem;
  background: white;
  box-shadow: 0 0 20px rgba(0, 0, 0, .1);
  width: 600px;
  color: black;
  font-weight: 400;
}

.header {
  &InvoiceNumber {
    color: gray;
    text-decoration: underline;
    font-size: 20px;
  }
}

.download {
  position: relative;
  width: 100%;
  list-style: none;
  font-weight: 400;

  &Item {
    padding-top: 20px;

    &Icon {
      position: absolute;
      right: 0;
      margin-right: 120px;
      color: indigo;
      transition: color .5s;

      &:hover {
        transition: color .5s;
        color: rgba(indigo, 0.7);
        animation: iconScale .6s infinite;
      }
    }
  }
}

.backBtn {
  position: absolute;
  bottom: 0;
  margin-bottom: 50px;
  background: indigo;
  border-color: indigo;
  border-radius: 4px;
  color: white;
  &:hover {
    background: rgba(indigo, 0.7);
    border-color: rgba(indigo, 0.7);
  }
  &:active {
    background: indigo !important;
    border-color: indigo !important;
  }
}

@keyframes iconScale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  75% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}
